import request from '@/utils/request'

// 分页查询检测通道描述
export function pageChannel(query) {
  return request({
    url: '/checkDesc/channel/page',
    method: 'get',
    params: query
  })
}

// 新增检测通道描述
export function addChannel(data) {
  return request({
    url: '/checkDesc/channel',
    method: 'post',
    data: data
  })
}

// 修改检测通道描述
export function updateChannel(data) {
  return request({
    url: '/checkDesc/channel',
    method: 'put',
    data: data
  })
}

// 删除试剂通道名称配置
export function delChannel(name) {
  return request({
    url: '/checkDesc/channel/' + name,
    method: 'delete'
  })
}

// 分页查询检测项目描述
export function pageProject(query) {
  return request({
    url: '/checkDesc/project/page',
    method: 'get',
    params: query
  })
}

// 新增检测项目描述
export function addProject(data) {
  return request({
    url: '/checkDesc/project',
    method: 'post',
    data: data
  })
}

// 修改检测项目描述
export function updateProject(data) {
  return request({
    url: '/checkDesc/project',
    method: 'put',
    data: data
  })
}

// 删除试剂项目名称配置
export function delProject(name) {
  return request({
    url: '/checkDesc/project/' + name,
    method: 'delete'
  })
}

export function checkProjectChannelExist(projectName) {
  return request({
    url: '/checkDesc/checkProjectChannelExist/' + projectName,
    method: 'get'
  })
}
